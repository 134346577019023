<template>
  <transition name="fade">
    <div class="modal-container" :class='className' v-if="show" @click.self="$emit('close')">
      <div class="modal-content" :style="{'max-width': width+'px'}">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ve-modal",
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false
    },
    width: {
      type: Number,
      default: 500,
      required: false
    },
    className: {
      type: String,
      required: false
    }
	},
	// emits: ['close'],
};
</script>

<style lang="scss" scoped>
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  padding-bottom: 42px;
  min-height: 600px;
  overflow: auto;
  &.fade-enter,
  &.fade-leave-to {
    opacity: 0;
  }
  &.fade-enter-active,
  &.fade-leave-active {
    transition: opacity 0.25s;
  }
  .modal-content {
    text-align: right;
    width: 100%;
    margin: 120px auto;
    background-color: #fff;
    padding: 30px 50px 30px;
    box-sizing: border-box;
  }
  &.dark {
    .modal-content {
      background: #313F46;
      color: #fff;
    }
  }
}
@media only screen and (max-width: 900px) {
  .modal-content {
    padding: 33px 19px !important;
  }
}
</style>