import Swal from 'sweetalert2'
var message_list = ''

function showMessage(message,status,data=null) {
    message_list = ''
    if (!status && data) {
        getDeepthValues(data)
    }
    Swal.fire({
        title: message,
        html: message_list,
        icon: status?'success':'error',
        showConfirmButton: false,
        timer: 2000
    })
}

function getDeepthValues(object_d) {
    let property_text
    for (let property1 in object_d) {
        if (isNaN(property1)) {
            if (typeof object_d[property1][0] === 'string') {
                property_text = property1;
            } else {
                message_list += `<h4>${property1}: </h4>`;
            }
        }
        if (typeof object_d[property1] === 'object') {

            getDeepthValues(object_d[property1]);
        } else {
            message_list += `<li style="margin-left: 18px;list-style-type: none;"><b><i class="icon-cross3 text-danger-600"></i></b> ${object_d[property1]}</li>`;
        }
    }
}


export default showMessage;