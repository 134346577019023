<template>
  <div id="root"></div>
</template>
<script>
import { verifyCard } from "@/apis/services";
import { GO_SELL_PUBLIC_KEY } from "@/config/index";

export default {
  props: {
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      goSell: null
    };
  },
  computed: {
    selectedPlan() {
      return this.$store.state.selectedPlan;
    },
    couponCode() {
      return this.$store.state.couponCode;
    }
  },
  methods: {
    openLightBox() {
      this.goSell.openLightBox();
    }
  },
  mounted() {
    window.goSell = require("/public/assets/js/goSell");
    this.goSell = window.goSell;
    this.goSell.config({
      containerID: "root",
      gateway: {
        publicKey: GO_SELL_PUBLIC_KEY,
        merchantId: null,
        language: "ar",
        contactInfo: true,
        supportedCurrencies: "all",
        supportedPaymentMethods: "all",
        saveCardOption: true,
        customerCards: true,
        notifications: "standard",
        callback: async response => {
          if (response.callback.error || response.callback.errors) return;

          if (!this.edit) {
            response.callback.subscription_plan = this.selectedPlan.product_id;
            response.callback.card.subscription_plan = this.selectedPlan.product_id;
          } else {
            delete response.callback.subscription_plan;
            delete response.callback.card.subscription_plan;
          }

          response.callback.couponCode = this.couponCode;
          response.callback.card.couponCode = this.couponCode;

          let result = await verifyCard(response.callback);
          if (result.data.success) {
            if (result.data.data.threeDSecure) {
              // setTimeout(() => {
              window.location.href = result.data.data.redirect_url;
              // }, 200);
            } else {
              this.$emit(
                "showMessage",
                result.data.message,
                true,
                result.data.data.card,
                result.data.data.threeDSecure
              );
            }
          } else {
            this.$router.push({
              path: "/confirmation-page",
              query: { status: "failed" }
            });
          }
        },
        onClose: () => {
          console.log("onClose Event");
          // this.$emit("closeLightBox");
        },
        backgroundImg: {
          url: "imgURL",
          opacity: "0.5"
        },
        labels: {
          cardNumber: "Card Number",
          expirationDate: "MM/YY",
          cvv: "CVV",
          cardHolder: "Name on Card",
          actionButton: "اشترك"
        },
        style: {
          base: {
            color: "#535353",
            lineHeight: "18px",
            fontFamily: "sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "rgba(0, 0, 0, 0.26)",
              fontSize: "15px"
            }
          },
          invalid: {
            color: "red",
            iconColor: "#fa755a "
          }
        }
      },

      transaction: {
        mode: "token",
        charge: {
          saveCard: true,
          threeDSecure: true,
          description: "Test Description",
          statement_descriptor: "Sample",
          metadata: {},
          receipt: {
            email: false,
            sms: true
          },
          redirect: "https://muktasar.co/test_redirect",
          post: null
        }
      }
    });
  }
};
</script>