<template>
  <div id="profile">
    <div class="profile-page">
      <div class="account-info">
        <div class="position-relative">
          <h1>بيانات حسابي</h1>
          <span
            class="form-action edit-icon"
            @click="editUser('name')"
            v-if="!canEditUser||section!=='name'"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.88086 2.68726L1.07744 11.4913C1.03315 11.5357 1.00117 11.5918 0.985939 11.6521L0.0101637 15.5687C-0.0190245 15.6866 0.0156207 15.8119 0.101662 15.898C0.166765 15.9631 0.255472 15.9991 0.346209 15.9991C0.374001 15.9991 0.402428 15.9957 0.430094 15.9887L4.34665 15.0128C4.40769 14.9976 4.46315 14.9657 4.50744 14.9214L13.3116 6.11802L9.88086 2.68726Z"
                fill="#5C7C8B"
              />
              <path
                d="M15.4926 1.48681L14.5126 0.50685C13.8577 -0.148108 12.7162 -0.147474 12.062 0.50685L10.8616 1.70725L14.2922 5.13788L15.4926 3.93748C15.8198 3.61045 16 3.17516 16 2.71221C16 2.24926 15.8198 1.81398 15.4926 1.48681Z"
                fill="#5C7C8B"
              />
            </svg>
          </span>
          <span class="form-action" v-if="canEditUser&&section=='name'">
            <button class="btn-main btn-plain btn-small d-block" @click="editUserInfo">حفظ</button>
          </span>
        </div>
        <div class="register-form">
          <label>الإسم</label>
          <template v-if="canEditUser&&section=='name'">
            <input
              id="user-name"
              type="text"
              v-model="user.name"
              class="register-form-control"
              placeholder="الإسم"
            />
          </template>
          <div class="register-form-control" v-else>{{user.name}}</div>
        </div>
        <div class="register-form">
          <label>البريد الإلكتروني</label>
          <template v-if="canEditUser&&section=='name'">
            <input
              type="email"
              v-model="user.email"
              class="register-form-control"
              placeholder="البريد الإلكتروني"
            />
          </template>
          <div class="register-form-control" v-else>{{user.email}}</div>
        </div>
        <div class="pass-section">
          <div class="position-relative">
            <h1>كلمة المرور</h1>
            <span
              class="form-action edit-icon"
              @click="editUser('password')"
              v-if="!canEditUser||section!=='password'"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.88086 2.68726L1.07744 11.4913C1.03315 11.5357 1.00117 11.5918 0.985939 11.6521L0.0101637 15.5687C-0.0190245 15.6866 0.0156207 15.8119 0.101662 15.898C0.166765 15.9631 0.255472 15.9991 0.346209 15.9991C0.374001 15.9991 0.402428 15.9957 0.430094 15.9887L4.34665 15.0128C4.40769 14.9976 4.46315 14.9657 4.50744 14.9214L13.3116 6.11802L9.88086 2.68726Z"
                  fill="#5C7C8B"
                />
                <path
                  d="M15.4926 1.48681L14.5126 0.50685C13.8577 -0.148108 12.7162 -0.147474 12.062 0.50685L10.8616 1.70725L14.2922 5.13788L15.4926 3.93748C15.8198 3.61045 16 3.17516 16 2.71221C16 2.24926 15.8198 1.81398 15.4926 1.48681Z"
                  fill="#5C7C8B"
                />
              </svg>
            </span>
            <span class="form-action" v-if="canEditUser&&section=='password'" @click="editUserInfo">
              <button class="btn-main btn-plain btn-small d-block" @click="editUserInfo">حفظ</button>
            </span>
          </div>
          <div class="register-form">
            <template v-if="canEditUser&&section=='password'">
              <input
                id="user-pass"
                type="password"
                v-model="user.password"
                class="register-form-control"
                placeholder="كلمة المرور"
              />
            </template>
            <div class="register-form-control" v-else>*****</div>
          </div>
        </div>
        <template>
          <h1>خطة الإشتراك</h1>
          <template v-if="subscription&&plan">
            <div class="row">
              <div class="col-auto mr-auto">
                <template v-if="plan.interval == 'MONTHLY'">
                  <button class="btn secondry-button" @click="upgradeSubscription">اشتراك سنوي</button>
                </template>
              </div>
              <div class="col-auto ml-auto">
                <h2>{{plan.name}}</h2>
              </div>
            </div>

            <template v-if="!isUpsell">
              <p class="plan-desc">
                <template
                  v-if="subscription.is_active && this.subscription.canceled"
                >ينتهي اشتراكك في تاريخ</template>
                <template v-else>
                  <span>سيتم خصم قيمة</span>
                  <span v-if="subscription.final_amount">{{subscription.final_amount.toFixed(2)}}</span>
                  ر.س في تاريخ
                </template>
                <span class="date">{{customFormatter(subscription.next_charge_date)}}</span>
              </p>
              <div class="cancel-subscription" v-if="!hideCancelBtn">
                <span
                  class="btn-flat"
                  @click="cancelConfirm=true,actionType='cancel'"
                >إلغاء الإشتراك</span>
              </div>
            </template>
          </template>
          <template v-if="!subscription">
            <div class="cancel-subscription">
              <span
                class="btn-flat"
                @click="$router.push({ path: '/' ,query:{ start: true } })"
              >انضم الآن</span>
            </div>
          </template>
        </template>

<!--        <div class="position-relative">-->
<!--          <h1>بيانات الدفع</h1>-->
<!--          <span class="form-action edit-icon" @click="editCard()">-->
<!--            <svg-->
<!--              width="16"-->
<!--              height="16"-->
<!--              viewBox="0 0 16 16"-->
<!--              fill="none"-->
<!--              xmlns="http://www.w3.org/2000/svg"-->
<!--            >-->
<!--              <path-->
<!--                d="M9.88086 2.68726L1.07744 11.4913C1.03315 11.5357 1.00117 11.5918 0.985939 11.6521L0.0101637 15.5687C-0.0190245 15.6866 0.0156207 15.8119 0.101662 15.898C0.166765 15.9631 0.255472 15.9991 0.346209 15.9991C0.374001 15.9991 0.402428 15.9957 0.430094 15.9887L4.34665 15.0128C4.40769 14.9976 4.46315 14.9657 4.50744 14.9214L13.3116 6.11802L9.88086 2.68726Z"-->
<!--                fill="#5C7C8B"-->
<!--              />-->
<!--              <path-->
<!--                d="M15.4926 1.48681L14.5126 0.50685C13.8577 -0.148108 12.7162 -0.147474 12.062 0.50685L10.8616 1.70725L14.2922 5.13788L15.4926 3.93748C15.8198 3.61045 16 3.17516 16 2.71221C16 2.24926 15.8198 1.81398 15.4926 1.48681Z"-->
<!--                fill="#5C7C8B"-->
<!--              />-->
<!--            </svg>-->
<!--          </span>-->
<!--        </div>-->
        <payment-form
          ref="goSell"
          :edit="true"
          v-if="openLightBox"
          @showMessage="setMessage"
          @closeLightBox="openLightBox=false"
        />
        <div class="register-form" v-if="card">
          <div class="d-flex flex-row-reverse brand-name" v-if="card.brand">
            <img :src="'/assets/images/icons/'+card.brand.toLowerCase()+'.png'" />
          </div>
          <div class="register-form-control">
            <span>************</span>
            {{card.last_four}}
          </div>
          <!-- <input
                        type="email"
                        :value="card.last_four"
          class="register-form-control">-->
        </div>
        <div class="delete-account">
          <h1>حذف الحساب</h1>
          <p
            class="desc pr-15"
          >بحذفك للحساب سيتم حذف كل البيانات المتعلقة بحسابك وسيتم إلغاء الاشتراك تلقائيا</p>
          <div class="text-right">
            <span class="btn-flat" @click="cancelConfirm=true,actionType='delete'">
              حذف الحساب
              <svg
                width="17"
                height="19"
                viewBox="0 0 17 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.0558 3.95539H12.2779C12.2779 1.86884 10.5865 0.17749 8.49998 0.17749C6.41347 0.17749 4.72208 1.86884 4.72208 3.95539H0.944213C0.422469 3.95539 -0.000244141 4.37815 -0.000244141 4.89985C-0.000244141 5.42156 0.422507 5.84431 0.944213 5.84431H1.322V17.3669C1.32426 18.7224 2.42212 19.8202 3.77762 19.8225H13.2223C14.5779 19.8202 15.6757 18.7224 15.678 17.3669V5.84431H16.0558C16.5775 5.84431 17.0002 5.42156 17.0002 4.89985C17.0002 4.37815 16.5775 3.95539 16.0558 3.95539ZM8.49998 2.06644C9.54305 2.06644 10.3889 2.91233 10.3889 3.95539H6.61103C6.61103 2.91229 7.45692 2.06644 8.49998 2.06644ZM13.789 17.3669C13.789 17.6797 13.5351 17.9335 13.2223 17.9335H3.77762C3.4648 17.9335 3.21095 17.6797 3.21095 17.3669V5.84431H13.789V17.3669Z"
                  fill="#FF7700"
                />
                <path
                  d="M6.422 14.7225C5.90025 14.7225 5.47754 14.2997 5.47754 13.778V10.0001C5.47754 9.47838 5.90029 9.05566 6.422 9.05566C6.9437 9.05566 7.36645 9.47842 7.36645 10.0001V13.778C7.36649 14.2997 6.94374 14.7225 6.422 14.7225Z"
                  fill="#F89715"
                />
                <path
                  d="M10.5775 14.7225C10.0558 14.7225 9.63306 14.2997 9.63306 13.778V10.0001C9.63306 9.47838 10.0558 9.05566 10.5775 9.05566C11.0993 9.05566 11.522 9.47842 11.522 10.0001V13.778C11.522 14.2997 11.0992 14.7225 10.5775 14.7225Z"
                  fill="#F89715"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>

    <loader v-if="loading" />
    <the-modal :show="cancelConfirm" @close="cancelConfirm=true" :width="320" :className="''">
      <div class="confirm-content modal-form">
        <template v-if="actionType=='cancel'">
          <p>هل أنت متأكد من أنك تريد إلغاء الاشتراك؟</p>
        </template>
        <template v-if="actionType=='delete'">
          <p>هل أنت متأكد من أنك تريد حذف الحساب؟</p>
        </template>
        <div class="form-footer mt-4">
          <div class="row">
            <div class="col-auto ml-auto">
              <a class="link-btn" @click="cancelConfirm=false">الغاء</a>
              <button
                type="button"
                class="btn main-button btn-small"
                style="width:100px;margin-left:15px"
                @click="actionType=='cancel'?cancelSubscription():deleteUser()"
              >نعم</button>
            </div>
          </div>
        </div>
      </div>
    </the-modal>
  </div>
</template>
<script>
import {
  getProfile,
  editUser,
  cancelSubscription,
  deleteUser
} from "@/apis/services";
import simpleDebounce from "@/mixin.js";
import moment from "moment";
import loader from "@/components/ui/loader";
import PaymentForm from "@/components/PaymentForm";
import TheModal from "@/components/ui/TheModal.vue";

import showMessage from "@/utils/notification";
export default {
  mixins: [simpleDebounce],
  components: {
    loader,
    PaymentForm,
    TheModal
  },
  data() {
    return {
      user: {
        name: "",
        email: " ",
        password: ""
      },
      plan: {
        name: ""
      },
      subscription: {
        amount: "",
        plan_expiration_date: ""
      },
      card: {},
      cards: [],
      canEditUser: false,
      loading: false,
      section: "",
      openLightBox: false,
      message: "",
      successStatus: true,
      hideCancelBtn: false,
      showModal: false,
      actionType: "",
      cancelConfirm: false,
      isUpsell: false
    };
  },
  watch: {},
  methods: {
    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getProfile() {
      getProfile()
        .then(res => {
          this.user = res.data.data.user;
          this.plan = res.data.data.plan;
          this.subscription = res.data.data.subscription;
          this.card = res.data.data.card;
          this.hideCancelBtn =
            this.subscription.is_active &&
            this.subscription.canceled &&
            !this.subscription.expired;
          this.isUpsell = this.subscription?.plan?.product_id == "UPSELL";
        })
        .catch(e => {
          //this.$router.push({ path: '/' })
        });
    },
    editUser(type) {
      this.section = type;
      this.canEditUser = true;
      this.simpleDebounce(() => {
        let inputId = "user-name";
        if (this.section == "password") {
          inputId = "user-pass";
        }
        document.getElementById(inputId).focus();
      }, 350 /* optional debounce time: default is 300 */);
    },
    editUserInfo() {
      try {
        this.loading = true;
        editUser(this.user)
          .then(res => {
            this.loading = false;
            if (res.data.success) {
              this.canEditUser = false;
              this.user.password = "";
              this.message = " تم تعديل بيانات المستخدم بنجاح ";
              this.$store.commit("SET_MESSAGE", {
                message: this.message,
                errors: null,
                status: res.data.success
              });
              //showMessage( this.message ,res.data.success)
            } else {
              this.$store.commit("SET_MESSAGE", {
                message: res.data.message,
                errors: res.data.data,
                status: res.data.success
              });
              //showMessage( res.data.message ,res.data.success,res.data.data)
            }
          })
          .catch(e => {
            this.loading = false;
          });
      } catch {}
    },
    editCard() {
      this.openLightBox = true;
      setTimeout(() => {
        this.$refs.goSell.openLightBox();
      }, 350);
    },
    setMessage(message, status, card = null) {
      this.$store.commit("SET_MESSAGE", {
        message: message,
        errors: null,
        status: status
      });
      //showMessage( message , status)
      this.card = card;
    },
    openCancelConfirm() {
      this.cancelConfirm = true;
    },
    cancelSubscription() {
      try {
        this.cancelConfirm = false;
        this.loading = true;
        cancelSubscription()
          .then(res => {
            this.loading = false;
            if (res) {
              this.$store.commit("SET_MESSAGE", {
                message: res.data.message,
                errors: null,
                status: res.data.success
              });
              //showMessage( res.data.message , res.data.success)

              if (res.data.success) {
                this.hideCancelBtn = true;
              }
              this.getProfile();
            }
          })
          .catch(e => {
            this.loading = false;
          });
      } catch (e) {
        this.loading = false;
      }
    },
    deleteUser() {
      try {
        this.loading = true;
        this.cancelConfirm = false;
        deleteUser()
          .then(res => {
            this.loading = false;
            this.$store.commit("SET_MESSAGE", {
              message: res.data.message,
              errors: null,
              status: res.data.success
            });
            //showMessage( res.data.message , res.data.success)
            if (res.data.success) {
              localStorage.removeItem("access_token");
              localStorage.removeItem("user");
              this.$store.commit("SET_USER", null);
              setTimeout(() => {
                this.$router.push({ path: "/" });
              }, 200);
            }
          })
          .catch(e => {
            this.loading = false;
          });
      } catch (e) {
        this.loading = false;
      }
    },
    upgradeSubscription() {
      this.$router
        .push({ path: "/register-pay", query: { upgrade: true } })
        .catch(() => {});
    }
  },
  mounted() {
    this.getProfile();
    if (this.$route.query) {
      if (this.$route.query.save_card == "failed") {
        this.$store.commit("SET_MESSAGE", {
          message: " فشلت عملية الدفع",
          errors: null,
          status: false
        });
      } else if (this.$route.query.save_card == "success") {
        this.$store.commit("SET_MESSAGE", {
          message: " تم حفظ البطاقة بنجاح  ",
          errors: null,
          status: true
        });
      }
    }
  },
  beforeDestroy: function() {
    this.openLightBox = false;
  }
};
</script>
<style scoped>
.btn-small {
  font-size: 15px;
}
</style>